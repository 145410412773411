import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core"
import { BrowserModule } from "@angular/platform-browser"
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"

import { AppComponent } from "./app.component"
import { WordListComponent } from "./word-list/word-list.component"
import { WordFilterComponent } from "./word-filter/word-filter.component"
import { WordTextComponent } from "./word-text/word-text.component"
import { WordScrollComponent } from "./word-scroll/word-scroll.component"
import { WordCategoryComponent } from "./word-category/word-category.component"

import { MatSliderModule } from "@angular/material/slider"
import { MatInputModule } from "@angular/material/input"
import { MatFormFieldModule } from "@angular/material/form-field"
import { MatSelectModule } from "@angular/material/select"
import { MatIconModule } from "@angular/material/icon"
import { MatTableModule } from "@angular/material/table"

@NgModule({
	declarations: [AppComponent, WordListComponent, WordFilterComponent, WordTextComponent, WordScrollComponent, WordCategoryComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		MatSliderModule,
		MatInputModule,
		MatSelectModule,
		MatFormFieldModule,
		MatIconModule,
		MatTableModule,
	],
	providers: [],
	bootstrap: [AppComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
