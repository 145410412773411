import { Component, EventEmitter, Input, Output } from "@angular/core"
import { Word } from "../app.interface"

@Component({
	selector: "app-word-list",
	templateUrl: "./word-list.component.html",
	styleUrls: ["./word-list.component.css"],
})
export class WordListComponent {
	@Input() filter: string = ""
	@Input() words: Word[] = []
	@Output() scroll = new EventEmitter<number>()
	displayedColumns: string[] = ["svenska", "uzbekiska", "category"]
	constructor() {}

	onTableScroll(event: any) {
		this.scroll.emit(event)
	}
}
