import { Component, Input, SimpleChanges } from "@angular/core"

@Component({
	selector: "app-word-text",
	templateUrl: "./word-text.component.html",
	styleUrls: ["./word-text.component.css"],
})
export class WordTextComponent {
	@Input() filter: string = ""
	@Input() text: string = ""
	displayText: String = ""
	constructor() {}
	private _currentFilter = ""
	private _currentText = ""

	// if input updates
	ngOnChanges(changes: SimpleChanges) {
		if (changes["filter"]?.currentValue) {
			this._currentFilter = changes["filter"].currentValue
		}
		if (changes["text"]?.currentValue) {
			this._currentText = changes["text"].currentValue
		}
		this._updateText(this._currentText)
	}

	private _capitalize(text: string): string {
		return text.charAt(0).toLocaleUpperCase() + text.slice(1)
	}

	private _updateText(text: string): void {
		// If no filter, just capitalize and return
		if (!this._currentFilter) {
			this.displayText = this._capitalize(text)
			return
		}

		let textArr = text.toLocaleLowerCase().split(this._currentFilter.toLocaleLowerCase())
		// If split did not find nothing to split on
		if (textArr.length == 1) {
			this.displayText = this._capitalize(text)
			return
		}

		// If first string in array is the filter word, capitalize it.
		if (textArr[0] == "") {
			textArr.shift()
			text =
				"<strong class='highlight'>" +
				this._capitalize(this._currentFilter) +
				"</strong>" +
				textArr.join("<strong class='highlight'>" + this._currentFilter + "</strong>")
			this.displayText = text
		} else {
			// else join the string and add filtered word in orange
			text = textArr.join("<strong class='highlight'>" + this._currentFilter + "</strong>")
			this.displayText = this._capitalize(text)
		}
	}
}
