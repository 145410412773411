import { Component, EventEmitter, Output } from "@angular/core"
// import Words from "../data/PartOfLexikon.json"
import Words from "../data/AllOfLexikon.json"
import { Word } from "./app.interface"

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.css"],
})
export class AppComponent {
	title = "Lexikon"

	private wordsAll: Word[] = []
	categoriesAll: string[] = []
	categoriesSelected: string[] = []
	numberOfRows = 16
	filteredWords: Word[] = []
	currentRow = 0
	totalRows = 0
	words: Word[] = []
	filter: string = ""
	filterLowercase: string = ""

	constructor() {
		this.wordsAll = Words.map((w) => {
			// populate array of all categorize
			const category = w.Kategori.trim()
			if (this.categoriesAll.indexOf(category) == -1) {
				this.categoriesAll.push(category)
			}
			// create a structured object of Words
			return {
				category: category,
				header: w.Rubrik.trim(),
				sw: w.Svenska.trim(),
				uz: w.Uzbekiska.trim(),
				id: w.id,
				selected: false,
				visible: true,
			} as Word
		}).sort(this._sortByAlphabet)

		this.categoriesAll.sort()
	}

	onCategories(categories: string[]) {
		this.categoriesSelected = categories
		this.onFilter(this.filter)
	}

	onFilter(filterValue: string) {
		// update filter first
		this.filter = filterValue
		this.filterLowercase = filterValue.toLowerCase()
		this.currentRow = 0
		// loop all words and test if they contain the lowercase value.
		this.filteredWords = this.wordsAll.filter(this._filterByFilterAndVisibility.bind(this)).filter(this._filterByCategories.bind(this))

		if (filterValue) {
			this.filteredWords.sort(this._sortByLength)
		} else {
			this.filteredWords.sort(this._sortByAlphabet)
		}
		this.totalRows = this.filteredWords.length
		this.words = this.filteredWords.slice(this.currentRow, this.numberOfRows)
	}

	onSliderScroll(value: number) {
		let endOfRange = value + this.numberOfRows
		if (endOfRange > this.filteredWords.length - 1) {
			endOfRange = this.filteredWords.length
		} else {
			this.currentRow = value
		}
		this.words = this.filteredWords.slice(this.currentRow, endOfRange)
	}

	ngOnInit() {
		this.totalRows = this.wordsAll.length
		this.filteredWords = [...this.wordsAll].sort(this._sortByAlphabet)
		this.words = this.wordsAll.slice(this.currentRow, this.numberOfRows)
	}

	private _filterByCategories(aWord: Word) {
		if (this.categoriesSelected.length == 0) {
			return true
		}
		return this.categoriesSelected.indexOf(aWord.category) > -1
	}

	private _filterByFilterAndVisibility(aWord: Word): boolean {
		return (aWord.visible = aWord.sw.toLowerCase().indexOf(this.filterLowercase) > -1)
	}

	private _sortByLength(aWord: Word, bWord: Word): number {
		return aWord.sw.length - bWord.sw.length
	}

	private _sortByAlphabet(aWord: Word, bWord: Word): number {
		return aWord.sw < bWord.sw ? -1 : 1
	}
}
