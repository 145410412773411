<h2 style="margin: 10px">{{ title | titlecase }} {{ filteredWords.length }}</h2>
<div style="margin: 10px; display: inline-block">
	<button mat-fab extended (click)="categoryComponent.onReset(); filterComponent.onReset()">
		<mat-icon>restore</mat-icon>
		Reset
	</button>
</div>
<div style="margin: 10px; display: inline-block">
	<app-word-category #categoryComponent (categories)="onCategories($event)" [categoriesAll]="categoriesAll"></app-word-category>
</div>
<div style="margin: 10px; display: inline-block">
	<app-word-filter #filterComponent (filter)="onFilter($event)"></app-word-filter>
</div>

<br />

<div style="margin: 10px; display: inline-block; width: 90%">
	<app-word-list [words]="words" [filter]="filter" (scroll)="slideScroller.onWheelScroll($event)"></app-word-list>
</div>
<div style="margin: 10px; display: inline-block; position: absolute; height: 100%; margin-top: 4%">
	<app-word-scroll
		#slideScroller
		(scroll)="onSliderScroll($event)"
		(mousewheel)="slideScroller.onWheelScroll($event)"
		[currentRow]="currentRow"
		[totalRows]="totalRows - numberOfRows"
	></app-word-scroll>
</div>
