import { Component, EventEmitter, OnInit, Output } from "@angular/core"

@Component({
	selector: "app-word-filter",
	templateUrl: "./word-filter.component.html",
	styleUrls: ["./word-filter.component.css"],
})
export class WordFilterComponent {
	// @Input() words: Word[] = []
	@Output() filter = new EventEmitter<string>()
	value = ""
	constructor() {}
	onFilter(val: string) {
		this.value = val
		this.filter.emit(val)
	}

	onReset() {
		this.value = ""
		this.filter.emit("")
	}
}
