import { Component, EventEmitter, OnInit, Output, Input } from "@angular/core"
import { FormControl } from "@angular/forms"

/** @title Select with multiple selection */
@Component({
	selector: "app-word-category",
	templateUrl: "./word-category.component.html",
})
export class WordCategoryComponent {
	@Input() categoriesAll: string[] = []
	@Output() categories = new EventEmitter<string[]>()
	categoriesControl = new FormControl("")

	categoriesSelected: string[] = []

	onCategories(values: string[]) {
		this.categoriesSelected = values
		this.categories.emit(values)
	}

	onReset() {
		console.log("Restet category")
		// this.filter = ""
		this.categoriesSelected = []
		this.categories.emit([])
	}
}
