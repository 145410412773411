<table mat-table [dataSource]="words" (mousewheel)="onTableScroll($event)">
	<!-- Position Column -->
	<ng-container matColumnDef="svenska">
		<th mat-header-cell *matHeaderCellDef>Svenska</th>
		<td mat-cell *matCellDef="let aWord">
			<!--  -->
			<app-word-text [text]="aWord.sw" [filter]="filter"></app-word-text>
		</td>
	</ng-container>

	<!-- Name Column -->
	<ng-container matColumnDef="uzbekiska">
		<th mat-header-cell *matHeaderCellDef>Uzbekiska</th>
		<td mat-cell *matCellDef="let aWord">
			<app-word-text [text]="aWord.uz"></app-word-text>
		</td>
	</ng-container>

	<!-- Name Column -->
	<ng-container matColumnDef="category">
		<th mat-header-cell *matHeaderCellDef>Kategori</th>
		<td mat-cell *matCellDef="let aWord">{{ aWord.category }}</td>
	</ng-container>

	<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
	<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
<!-- <div *ngFor="let word of words">
	<i *ngIf="word.visible">{{ word.sw | titlecase }} - {{ word.uz | titlecase }}</i>
</div> -->
