import { Component, EventEmitter, OnInit, Output, Input, SimpleChanges } from "@angular/core"

@Component({
	selector: "app-word-scroll",
	templateUrl: "./word-scroll.component.html",
})
export class WordScrollComponent {
	// @Input() words: Word[] = []
	@Output() scroll = new EventEmitter<number>()
	@Input() currentRow: number = 0
	@Input() totalRows: number = 0

	value: number = 0

	constructor() {}

	// if input updates
	ngOnChanges(changes: SimpleChanges) {
		if (changes["currentRow"]?.currentValue) {
			this.value = changes["currentRow"].currentValue
		}

		if (changes["totalRows"]?.currentValue) {
			this.value = 0
		}
	}

	onSliderScroll(event: any) {
		this.value = event.value
		this.scroll.emit(event.value)
	}

	onWheelScroll(event: any) {
		const delta = event.deltaY / 100
		this.value = Math.min(this.totalRows, Math.max(this.value + delta, 0))
		this.scroll.emit(this.value)
	}
}
