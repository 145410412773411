<mat-form-field>
	<mat-label>Categories</mat-label>
	<mat-select
		#categoryMultiSelect
		[formControl]="categories"
		[value]="categoriesSelected"
		multiple
		(selectionChange)="onCategories(categoryMultiSelect.value)"
	>
		<mat-option *ngFor="let category of categoriesAll" [value]="category">{{ category }}</mat-option>
	</mat-select>
</mat-form-field>
